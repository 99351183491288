import type { Attachment } from '#attachment';

export enum UserRoleEnum {
  User = 'user',
  Admin = 'admin_app',
}

export interface User {
  first_name?: string;
  last_name?: string;
  email: string;
  role: UserRoleEnum;
  language: string;
  id: string;
  picture_url: string;
  client_id: number;
  attachments: Attachment[];
  disabled: boolean;
  client?: {
    id: number;
    name: string;
    __typename: 'clients';
  };
  _created_at: string;
  _created_by: string;
  _updated_at: string;
  _updated_by: string;
  __typename: 'users';
}

export interface UserCreate extends User {
  password?: string;
}
