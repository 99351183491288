import { aiContextFragment } from '#fragments/ai_context';
import { clientCompanyFragment } from '#fragments/client_companies';
import { clientSettingFragment } from '#fragments/client_settings';
import { CommercialGoalFragment } from '#fragments/commercialGoals';
import { taxWithLatestVersionFragment } from '#fragments/taxes';
import { termFragment } from '#fragments/terms';
import { gql } from '@apollo/client/core';

export const clientFragment = gql`
fragment ClientFragment on clients {
  id
  name
  slug
  address
  zip_code
  city
  country
  phone_number
  company_number
  vat_number
  attachments

  _created_at
  _created_by
  _updated_at
  _updated_by
}
`;

export const clientCompleteFragment = gql`
${clientFragment}
${clientSettingFragment}
${termFragment}
${taxWithLatestVersionFragment}
${CommercialGoalFragment}
${aiContextFragment}
${clientCompanyFragment}
fragment ClientCompleteFragment on clients {
  ...ClientFragment
  terms {
    ...TermFragment
  }
  client_setting {
    ...ClientSettingFragment
  }
  taxes {
    ...TaxWithLatestVersionFragment
  }
  commercial_goal {
    ...CommercialGoalFragment
  }
  ai_context {
    ...AiContextFragment
  }
  client_companies(where: { _deleted_at: { _is_null: true } }) {
    ...ClientCompanyFragment
  }
}
`;
