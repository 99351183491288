import { gql } from '@apollo/client/core';

export const contactFragment = gql`
fragment ContactFragment on contacts {
  id
  customer_id
  name
  email
  phone
  role
  _created_at
  _updated_at
  _created_by
  _updated_by
}
`;
