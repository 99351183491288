import { databaseKpiFragment } from '#fragments';
import { databaseVersionFragment } from '#fragments/database';
import { moduleRecursiveFragment } from '#fragments/modules';
import { quoteItemSubcontractingFragment, quoteItemTaxFragment } from '#fragments/quoteSection';
import { gql } from '@apollo/client/core';

export const QUOTE_ITEM_MODULE = gql`
  query quote_item_modules {
    quote_item_modules(where: { database_object: { _deleted_at: { _is_null: true } } }) {
      id
      data
      value
      margin
      quote_section_id

      object_id
      database_object {
        name
        id
        data
        margin
        database_id
        _updated_by
        _updated_at
        _created_by
        _created_at
        _deleted_at
        database {
          name
          attributes
          id
        }
      }
      _updated_by
      _updated_at
      _created_by
      _created_at
    }
  }
`;

export const QUOTE_ITEM_MODULE_CREATE = gql`
  ${moduleRecursiveFragment}
  mutation CreateQuoteItemModule($object: quote_item_module_insert_input!) {
    insert_quote_item_module_one(object: $object) {
      _created_at
      _created_by
      _updated_at
      _updated_by
      data
      id
      quote_section_id
      value
      module_id
      margin
     module {
      ...ModuleRecursiveFragment
     }
    }
  }
`;

export const QUOTE_ITEM_MODULE_UPDATE = gql`
 ${moduleRecursiveFragment}
  mutation UpdateQuoteItemModule($object: quote_item_module_set_input!, $id: Int!) {
    update_quote_item_module_by_pk(pk_columns:{ id:$id }, _set: $object) {
        id
        data
        value
        margin
        quote_section_id
        module_id
        module {
        ...ModuleRecursiveFragment
        }
    }
  }
`;

export const QUOTE_ITEM_MODULE_BULK_UPDATE = gql`
  mutation UpdateQuoteItemModules($updates: [quote_item_module_updates!]!) {
    update_quote_item_module_many(updates: $updates) {
      affected_rows
    }
  }
`;

export const QUOTE_ITEM_MODULE_DELETE = gql`
  mutation DeleteQuoteItemModule($id: Int!) {
    delete_quote_item_module(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const QUOTE_ITEM_OBJECTS = gql`
  query quote_item_objects {
    quote_item_objects(where: { database_object: { _deleted_at: { _is_null: true } } }) {
      id
      data
      value
      margin
      quote_section_id

      database_object_id
      database_object {
        id
        latest_database_object_version(where: { is_latest: { _eq: true } }) {
          id
          name
          data
          version
          margin
          database_object_id
          is_latest
          _created_at
          _created_by
          _updated_at
          _updated_by
        }

        database {
          id
          latest_database_version(where: { is_latest: { _eq: true } }) {
            attributes
            id
            name
          }
        }

        _updated_by
        _updated_at
        _created_by
        _created_at
        _deleted_at
      }
      _updated_by
      _updated_at
      _created_by
      _created_at
    }
  }
`;

export const QUOTE_ITEM_OBJECT_UPDATE = gql`
  mutation UpdateQuoteItemObject($object: quote_item_database_object_set_input!, $id: Int!) {
    update_quote_item_database_object(where: { id: { _eq: $id } }, _set: $object) {
      affected_rows
    }
  }
`;

export const QUOTE_ITEM_OBJECT_BULK_UPDATE = gql`
  mutation UpdateQuoteItemObjects($updates: [quote_item_database_object_updates!]!) {
    update_quote_item_database_object_many(updates: $updates) {
      affected_rows
    }
  }
`;

export const QUOTE_ITEM_OBJECT_CREATE = gql`
  ${databaseVersionFragment}
  ${databaseKpiFragment}
  mutation CreateQuoteItemObject($object: quote_item_database_object_insert_input!) {
    insert_quote_item_database_object_one(object: $object) {
      id
      data
      value
      margin
      database_object_id
      quote_section_id
      database_object {
        _updated_by
        _updated_at
        _created_by
        _created_at
        _deleted_at
        id
        latest_database_object_version(where: { is_latest: { _eq: true } }) {
          id
          name
          data
          version
          margin
          database_object_id
          is_latest
          _created_at
          _created_by
          _updated_at
          _updated_at
        }
        database_id
        database {
          id
          database_kpis {
            ...DatabaseKPIFragment
          }
          latest_database_version(where: { is_latest: { _eq: true } }) {
            ...DatabaseVersionFragment
          }
        }
      }
      _updated_by
      _updated_at
      _created_by
      _created_at
    }
  }
`;

export const QUOTE_ITEM_OBJECT_DELETE = gql`
  mutation DeleteQuoteItemDetabaseObject($id: Int!) {
    delete_quote_item_database_object(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const QUOTE_ITEM_SUBCONTRACTINGS_CREATE = gql`
${quoteItemSubcontractingFragment}
  mutation CreateQuoteItemSubcontractings($object: quote_item_subcontractings_insert_input!) {
    insert_quote_item_subcontractings_one(object: $object) {
      ...QuoteItemSubcontractingFragment
    }
  }
`;

export const QUOTE_ITEM_SUBCONTRACTINGS_UPDATE = gql`
  ${quoteItemSubcontractingFragment}
  mutation UpdateQuoteItemSubcontractings($object: quote_item_subcontractings_set_input!, $id: bigint!) {
    update_quote_item_subcontractings_by_pk(pk_columns: { id: $id }, _set: $object) {
      ...QuoteItemSubcontractingFragment
    }
  }
`;

export const QUOTE_ITEM_SUBCONTRACTINGS_BULK_UPDATE = gql`
  mutation UpdateQuoteItemSubcontractings($updates: [quote_item_subcontractings_updates!]!) {
    update_quote_item_subcontractings_many(updates: $updates) {
      affected_rows
    }
  }
`;

export const QUOTE_ITEM_SUBCONTRACTINGS_DELETE = gql`
  mutation DeleteQuoteItemSubcontractings($id: bigint!) {
    delete_quote_item_subcontractings(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const QUOTE_ITEM_TAX_CREATE = gql`
${quoteItemTaxFragment}
  mutation CreateQuoteItemTax($object: quote_item_taxes_insert_input!) {
    insert_quote_item_taxes_one(object: $object) {
      ...QuoteItemTaxFragment
    }
  }
`;

export const QUOTE_ITEM_TAX_UPDATE = gql`
${quoteItemTaxFragment}
  mutation UpdateQuoteItemTax($object: quote_item_taxes_set_input!, $id: bigint!) {
    update_quote_item_taxes_by_pk(pk_columns: { id: $id }, _set: $object) {
      ...QuoteItemTaxFragment
    }
  }
`;

export const QUOTE_ITEM_TAX_DELETE = gql`
  mutation DeleteQuoteItemTax($id: bigint!) {
    delete_quote_item_taxes(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
