import { quoteSettingFragment } from '#fragments/quotes';
import { gql } from '@apollo/client/core';

export const QUOTE_SETTINGS_QUERY_LIST = gql`
  ${quoteSettingFragment}
  query ListQuoteSettings($quoteId: Int!) {
    quote_settings(where: { quote_id: { _eq: $quoteId } }) {
      ...QuoteSettingFragment
    }
  }
`;

export const QUOTE_SETTINGS_QUERY = gql`
  ${quoteSettingFragment}
  query GetQuoteSetting($id: Int!) {
    quote_settings_by_pk(id: $id) {
      ...QuoteSettingFragment
    }
  }
`;

export const QUOTE_SETTINGS_INSERT = gql`
  ${quoteSettingFragment}
  mutation InsertQuoteSetting($object: quote_settings_insert_input!) {
    insert_quote_settings_one(object: $object) {
      ...QuoteSettingFragment
    }
  }
`;

export const QUOTE_SETTINGS_UPDATE = gql`
  ${quoteSettingFragment}
  mutation UpdateQuoteSetting($id: Int!, $object: quote_settings_set_input!) {
    update_quote_settings_by_pk(pk_columns: { id: $id }, _set: $object) {
      ...QuoteSettingFragment
    }
  }
`;

export const QUOTE_SETTINGS_DELETE = gql`
  mutation DeleteQuoteSetting($id: Int!) {
    delete_quote_settings_by_pk(id: $id) {
      id
    }
  }
`;
