import { tagFragment } from '#fragments/tag';
import { gql } from '@apollo/client/core';

export const TAGS_QUERY_LIST = gql`
  query TagsQuery {
    tags {
      ...TagFragment
    }
  }
  ${tagFragment}
`;

export const BULK_TAG_INSERT = gql`
  mutation BulkTagInsert($objects: [tags_insert_input!]!) {
    insert_tags(objects: $objects) {
      returning {
        ...TagFragment
      }
    }
  }
  ${tagFragment}
`;
