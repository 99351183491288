import { clientCompleteFragment, clientFragment } from '#fragments/clients';
import { gql } from '@apollo/client/core';

export const CLIENTS_QUERY_LIST = gql`
  ${clientFragment}
  query ClientQuery($where: clients_bool_exp) {
    clients(where: $where) {
      ...ClientFragment
    }
  }
`;

export const CLIENT_QUERY = gql`
  ${clientCompleteFragment}
  query ClientQueryByPk($id: Int!) {
    clients_by_pk(id: $id) {
      ...ClientCompleteFragment
    }
  }
`;

export const CLIENT_UPDATE = gql`
  ${clientCompleteFragment}
  mutation UpdateClient($object: clients_set_input!, $id: Int!) {
    update_clients_by_pk(pk_columns: { id: $id }, _set: $object) {
      ...ClientCompleteFragment
    }
  }
`;

export const CLIENT_INSERT = gql`
  ${clientCompleteFragment}
  mutation InsertClient($object: clients_insert_input!) {
    insert_clients_one(object: $object) {
      ...ClientCompleteFragment
    }
  }
`;
