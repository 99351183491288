import { referenceFragment, referenceVersionFragment } from '#fragments/references';
import { gql } from '@apollo/client/core';

export const REFERENCES_QUERY_LIST = gql`
${referenceFragment}
query ReferencesQueryList {
  references {
    ...ReferenceFragment
  }
}`;

export const REFERENCE_QUERY = gql`
${referenceFragment}
query ReferencesQueryById($id: Int!) {
  references_by_pk(id: $id) {
    ...ReferenceFragment
  }
}`;

export const REFERENCE_VERSIONS_QUERY = gql`
${referenceVersionFragment}
query ReferenceVersionsQuery($id: Int!) {
  reference_versions(where: { reference_id: { _eq: $id } }, order_by: { _created_at: desc }) {
    ...ReferenceVersionFragment
  }
}`;

export const REFERENCES_INSERT = gql`
${referenceFragment}
mutation ReferencesInsert($object: references_insert_input!) {
  insert_references_one(object: $object) {
    ...ReferenceFragment
  }
}`;

export const REFERENCES_UPDATE = gql`
${referenceVersionFragment}
  mutation UpdateReference($id: Int!, $object: reference_versions_insert_input!) {
    update_reference_versions(
      where: { _and: { reference_id: { _eq: $id }, is_latest: { _eq: true } } }
      _set: { is_latest: false }
    ) {
      affected_rows
    }
    insert_reference_versions_one(object: $object) {
      ...ReferenceVersionFragment
    }
  }
`;
