import { taxVersionFragment, taxWithLatestVersionFragment } from '#fragments/taxes';
import { gql } from '@apollo/client/core';

export const TAX_QUERY = gql`
  ${taxWithLatestVersionFragment}
  query TaxQueryByPk($id: bigint!) {
    taxes_by_pk(id: $id) {
      ...TaxWithLatestVersionFragment
    }
  }
`;

export const TAX_UPDATE = gql`
  ${taxVersionFragment}
  mutation UpdateTax($object: tax_versions_insert_input!, $taxId: bigint!) {
    update_tax_versions(
      where: { _and: { tax_id: { _eq: $taxId }, is_latest: { _eq: true } } }
      _set: { is_latest: false }
    ) {
      affected_rows
    }
    insert_tax_versions_one(object: $object) {
      ...TaxVersionFragment
    }
  }
`;

export const TAX_INSERT = gql`
  ${taxWithLatestVersionFragment}
  mutation InsertClient($object: taxes_insert_input!) {
    insert_taxes_one(object: $object) {
      ...TaxWithLatestVersionFragment
    }
  }
`;
