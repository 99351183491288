import { gql } from '@apollo/client/core';

export const quoteDiscountFragment = gql`
  fragment QuoteDiscountFragment on quote_discounts {
    id
    quote_id
    name
    order
    type
    value
    _created_at
    _created_by
    _updated_at
    _updated_by
  }
`;
