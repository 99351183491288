import { userFragment } from '#fragments/users';
import { gql } from '@apollo/client/core';

export const commentFragment = gql`
${userFragment}
fragment CommentFragment on comments {
  id
  content
  user {
    ...UserFragment
  }
  _created_at
  _created_by
  _updated_at
  _updated_by
}
`;

export const quoteCommentLinkFragment = gql`
${commentFragment}
fragment QuoteCommentLinkFragment on quote_comment_links {
  id
  quote_id
  comment_id
  comment {
    ...CommentFragment
  }
  _created_at
  _created_by
  _updated_at
  _updated_by
}
`;
