import { gql } from '@apollo/client/core';

export const aiContextFragment = gql`
  fragment AiContextFragment on ai_context {
    id
    client_id
    company_description
    _created_at
    _created_by
    _updated_at
    _updated_by
  }
`;
