import type { Unsubscribe } from 'firebase/auth';
import { fb_auth } from '~/services/firebase';
import type { UserModule } from '~/types';

let unsubscribe: Unsubscribe | null = null;

export const install: UserModule = ({ router }) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const query = Object.fromEntries(urlSearchParams.entries());
  if (unsubscribe) {
    unsubscribe();
  }
  router.beforeEach((to, _from, next) => {
    if (
      to.matched.some((record) => {
        return record.meta.requiresAuth;
      })
    ) {
      unsubscribe = fb_auth.onAuthStateChanged((user) => {
        if (user) {
          next();
        }
        else {
          next({
            path: '/auth/login',
            query,
          });
        }
      });
    }
    else {
      next();
    }
  });
};
