import { termFragment } from '#fragments/terms';
import { gql } from '@apollo/client/core';

export const TERM_INSERT = gql`
  ${termFragment}
 mutation TermInsert($object: terms_insert_input!) {
  insert_terms_one(object: $object) {
    ...TermFragment
  }
}
`;

export const TERM_UPDATE = gql`
  ${termFragment}
  mutation TermUpdate($object: terms_set_input!, $id: bigint!) {
    update_terms_by_pk(pk_columns: { id: $id }, _set: $object) {
      ...TermFragment
    }
  }
`;

export const TERM_DELETE = gql`
  mutation DeleteTerm($id: bigint!) {
    delete_terms_by_pk(id: $id) {
      id
    }
  }
`;
