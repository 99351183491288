import { databaseDataFragment, databaseVersionFragment } from '#fragments/database';
import { gql } from '@apollo/client/core';

export const DATABASES_QUERY_LIST = gql`
  ${databaseDataFragment}
  ${databaseVersionFragment}
  query DatabasesQuery {
    databases {
      ...DatabaseDataFragment
      latest_database_version(where: { is_latest: { _eq: true } }) {
        ...DatabaseVersionFragment
      }
    }
  }
`;

export const DATABASE_QUERY = gql`
  ${databaseDataFragment}
  ${databaseVersionFragment}
  query DatabaseQuery($id: Int!) {
    databases_by_pk(id: $id) {
      ...DatabaseDataFragment
      latest_database_version(where: { is_latest: { _eq: true } }) {
        ...DatabaseVersionFragment
      }
    }
  }
`;

export const DATABASE_UPDATE = gql`
  ${databaseDataFragment}
  ${databaseVersionFragment}
  mutation UpdateDatabase($object: database_versions_insert_input!, $id: Int!) {
    update_database_versions(
      where: { database_id: { _eq: $id }, is_latest: { _eq: true } }
      _set: { is_latest: false }
    ) {
      affected_rows
    }
    insert_database_versions_one(object: $object) {
      ...DatabaseVersionFragment
    }
  }
`;

export const DATABASE_INSERT = gql`
  ${databaseDataFragment}
  ${databaseVersionFragment}
  mutation InsertDatabase($object: databases_insert_input!) {
    insert_databases_one(object: $object) {
      ...DatabaseDataFragment
      latest_database_version(where: { is_latest: { _eq: true } }) {
        ...DatabaseVersionFragment
      }
    }
  }
`;

export const DATABASE_VERSION_INSERT = gql`
  ${databaseDataFragment}
  ${databaseVersionFragment}
  mutation InsertDatabaseVersion($object: database_versions_insert_input!) {
    insert_database_versions_one(object: $object) {
      ...DatabaseVersionFragment
    }
  }
`;

export const DATABASE_VERSION_UPDATE = gql`
  ${databaseDataFragment}
  ${databaseVersionFragment}
  mutation UpdateDatabaseVersion($object: database_versions_set_input!, $id: Int!) {
    update_database_versions_by_pk(pk_columns: { id: $id }, _set: $object) {
      ...DatabaseVersionFragment
    }
  }
`;
