import { quoteVersionFragment } from '#fragments/quoteVersion';
import { gql } from '@apollo/client/core';

const businessData = gql`
  ${quoteVersionFragment}
  fragment BusinessFragment on businesses {
    id
    quote_id
    quote_version_id
    real_cost
    inputs_json
    _created_at
    _created_by
    _updated_at
    _updated_by
    quote {
      name
    }
    quote_version {
      ...QuoteVersionFragment
    }
  }
`;

export const BUSINESS_QUERY_LIST = gql`
  ${businessData}
  query BusinessQuery {
    businesses {
      ...BusinessFragment
      quote {
        name
        id
        description
        date
        customer {
          name
          id
        }
        assignee_id
        attachments
      }
    }
  }
`;

export const BUSINESS_QUERY_LIST_BY_CLIENT_ID = gql`
  ${businessData}
  query BusinessQuery($clientId: Int!) {
    businesses(where: {quote: {customer: {client_id: {_eq: $clientId}}}}) {
      ...BusinessFragment
      quote {
        name
        id
        description
        date
        customer {
          name
          id
        }
        assignee_id
        attachments
      }
    }
  }
`;
export const BUSINESS_QUERY = gql`
  ${businessData}
  query BusinessQuery($id: Int!) {
    businesses_by_pk(id: $id) {
      ...BusinessFragment
    }
  }
`;

export const BUSINESS_UPDATE = gql`
  ${businessData}
  mutation UpdateBusiness($object: businesses_set_input!, $id: Int!) {
    update_businesses_by_pk(pk_columns: { id: $id }, _set: $object) {
      ...BusinessFragment
    }
  }
`;

export const BUSINESS_INSERT = gql`
  ${businessData}
  mutation InsertBusiness($object: businesses_insert_input!) {
    insert_businesses_one(object: $object) {
      ...BusinessFragment
    }
  }
`;
