<script setup lang="ts">
import { FeatureName, useFeaturesStore } from '~/stores/features';

const router = useRouter();
const { isEnabled } = useFeaturesStore();
if (isEnabled(FeatureName.dashboard)) {
  router.replace('/dashboard');
}
else {
  router.replace('/quotes');
}
</script>

<template>
  <div class="h-full w-[calc(100vw-2.5rem)] flex flex-col overflow-y-scroll text-primary md:w-[calc(100vw-15rem)]">
    <UiHeader />
    <div class="h-full w-full flex items-center justify-center">
      <Loader size="large" />
    </div>
  </div>
</template>

<route lang="yaml">
meta:
  layout: home
  requiresAuth: true
</route>
