import type { JWTPayload } from 'jose';
import { ApolloClient, concat, HttpLink, InMemoryCache, type RequestHandler } from '@apollo/client/core';
import { getIdTokenResult } from 'firebase/auth';
import { SignJWT } from 'jose';
import { fb_auth } from '~/services/firebase';

export async function getToken(): Promise<string | undefined> {
  const user = fb_auth.currentUser;
  const token: string | undefined = await user?.getIdToken();
  return token;
}

const httpLink = new HttpLink({ uri: import.meta.env.VITE_GRAPHQL_URL as string });

const authMiddleware: RequestHandler = async (operation, forward) => {
  // add the authorization to the headers
  const firebaseToken = await getToken();
  let token = firebaseToken;

  if (import.meta.env.DEV) {
    const userResult = await getIdTokenResult(fb_auth.currentUser!);
    try {
      token = await new SignJWT(userResult.claims as JWTPayload)
        .setProtectedHeader({ alg: 'HS256', typ: 'JWT' })
        .sign(new TextEncoder().encode('superlongjwtsecrettosignmytokenlocally'));
    }
    catch (e) {
      console.error('Error signing token', e);
    }
  }
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : 'token bad',
    },
  });
  return forward(operation);
};

const apolloClient = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
    },
  },
});

export default apolloClient;
