import { gql } from '@apollo/client/core';

export const userFragment = gql`
  fragment UserFragment on users {
    first_name
    last_name
    email
    role
    id
    picture_url
    client_id
    attachments
    disabled
    client {
      id
      name
    }
    _created_at
    _created_by
    _updated_at
    _updated_by
  }
`;
