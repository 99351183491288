import { computedKpiFragment, databaseKpiFragment, databaseKpiVersionFragment } from '#fragments';
import { gql } from '@apollo/client/core';

export const COMPUTED_KPIS_QUERY_LIST = gql`
  ${computedKpiFragment}
  query computedKpis {
    computed_kpis {
      ...ComputedKPIFragment
    }
  }
`;

export const COMPUTED_KPIS_INSERT = gql`
  ${computedKpiFragment}
  mutation insertComputedKpi($object: computed_kpis_insert_input!) {
    insert_computed_kpis_one(object: $object) {
      ...ComputedKPIFragment
    }
  }
`;

export const DATABASE_KPIS_INSERT = gql`
  ${databaseKpiFragment}
  mutation insertDatabaseKpi($object: database_kpis_insert_input!) {
    insert_database_kpis_one(object: $object) {
      ...DatabaseKPIFragment
    }
  }
`;

export const DATABASE_KPI_VERSION_UPDATE = gql`
  ${databaseKpiVersionFragment}
  mutation updateDatabaseKpiVersion($object: database_kpi_versions_insert_input!, $id: Int!) {
    update_database_kpi_versions(
      where: { database_kpi_id: { _eq: $id }, is_latest: { _eq: true } }
      _set: { is_latest: false }
    ) {
      affected_rows
    }
    insert_database_kpi_versions_one(object: $object) {
      ...DatabaseKpiVersionFragment
    }
  }
`;

export const DATABASE_KPIS_DELETE = gql`
  mutation deleteDatabaseKpi($id: Int!) {
    delete_database_kpis_by_pk(id: $id) {
      id
    }
  }
`;
