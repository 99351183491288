import { clientSettingFragment } from '#fragments/client_settings';
import { gql } from '@apollo/client/core';

export const CLIENT_SETTING_INSERT = gql`
  ${clientSettingFragment}
 mutation ClientSettingInsert($object: client_settings_insert_input!) {
  insert_client_settings_one(object: $object) {
    ...ClientSettingFragment
  }
}
`;

export const CLIENT_SETTING_UPDATE = gql`
  ${clientSettingFragment}
  mutation ClientSettingUpdate($object: client_settings_set_input!, $id: bigint!) {
    update_client_settings_by_pk(pk_columns: { id: $id }, _set: $object) {
      ...ClientSettingFragment
    }
  }
`;
