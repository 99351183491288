import { quoteCommentLinkFragment } from '#fragments/comments';
import { gql } from '@apollo/client/core';

export const COMMENTS_BY_QUOTE_ID = gql`
  ${quoteCommentLinkFragment}
  query CommentsByQuoteId($quoteId: bigint!, $limit: Int, $offset: Int) {
    quote_comment_links(
      where: { quote_id: { _eq: $quoteId } },
      limit: $limit,
      offset: $offset,
      order_by: { comment: { _created_at: desc } }
    ) {
      ...QuoteCommentLinkFragment
    }
  }
`;

export const COMMENT_INSERT = gql`
  ${quoteCommentLinkFragment}
  mutation InsertComment($object: quote_comment_links_insert_input!) {
    insert_quote_comment_links_one(object: $object) {
      ...QuoteCommentLinkFragment
    }
  }
`;
