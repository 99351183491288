import { gql } from '@apollo/client/core';

export const taxVersionFragment = gql`
fragment TaxVersionFragment on tax_versions {
  id
  tax_id
  name
  type
  apply_on
  value
  is_latest

  _created_at
  _created_by
  _updated_at
  _updated_by
}
`;

export const taxFragment = gql`
fragment TaxFragment on taxes {
  id
  client_id

  _created_at
  _created_by
  _updated_at
  _updated_by
}
`;

export const taxWithLatestVersionFragment = gql`
${taxFragment}
${taxVersionFragment}
fragment TaxWithLatestVersionFragment on taxes {
  ...TaxFragment
  tax_versions(where: { is_latest: { _eq: true } }) {
    ...TaxVersionFragment
  }
}
`;
