<script setup lang="ts">
import { useHead } from '@vueuse/head';
import { ConfigProvider } from 'ant-design-vue';
import { reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { useFeaturesStore } from '~/stores/features';
import { usersStore } from '~/stores/users';
import antThemeOverride from '~/themes/antDesign';
import { settingsStore } from './stores/settings';

//
useHead({
  title: 'Relief',
  meta: [{ name: 'description', content: 'Optimize your margins on your industrial projects' }],
});
//
const appData: any = reactive({
  loading: false,
});

function getNavigatorLanguage() {
  let language = 'en';
  if (typeof window !== 'undefined')
    language = navigator.language.substring(0, 2);

  return language;
}

const route = useRoute();
const router = useRouter();
const { locale } = useI18n();
const featureStore = useFeaturesStore();
const { firebaseUserFetched, isLoadingCurrentUser } = storeToRefs(usersStore());
async function init() {
  appData.loading = true;

  // load user local
  const locale_iso: string = getNavigatorLanguage(); // navigator.language.substring(0, 2)
  locale.value = locale_iso;

  let query: any = route?.query;
  if (!query?.go) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    query = Object.fromEntries(urlSearchParams.entries());
  }
  if (query?.go)
    router.replace(`/${query?.go}`);

  // Specific global store init
  featureStore.initFlags();

  // setDarkMode();
  appData.loading = false;
}

// function setDarkMode() {
//   useDark({
//     valueDark: 'light', // toggle this value back to 'dark' to re-enable dark mode
//     valueLight: 'light',
//   });

//   // Remove following line to re-enable dark mode
//   document.documentElement.classList.remove('dark');
// }

onMounted(() => {
  init();
});
</script>

<template>
  <ConfigProvider :theme="antThemeOverride">
    <router-view v-if="!appData.loading && firebaseUserFetched && !isLoadingCurrentUser" />
    <div v-else class="h-full w-full flex flex-col items-center justify-center">
      <!-- Animated Text -->
      <div class="text-secondary animate-ping text-3xl font-mono">
        Relief
      </div>
      <!-- User Store Loading Message -->
      <div class="pt-20 text-xl text-gray font-mono">
        {{ settingsStore().stores_loading_msg }}
      </div>
    </div>
  </ConfigProvider>
</template>
