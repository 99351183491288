import { userFragment } from '#fragments/users';
import { gql } from '@apollo/client/core';

export const USERS_QUERY_LIST = gql`
  ${userFragment}
  query UsersQuery($where: users_bool_exp) {
    users(where: $where) {
      ...UserFragment
    }
  }
`;

export const USER_QUERY = gql`
  ${userFragment}
  query UserByPk($id: String!) {
    users_by_pk(id: $id) {
      ...UserFragment
    }
  }
`;

export const UPDATE_USER = gql`
  ${userFragment}
  mutation UpdateUser($object: users_set_input!, $id: String!) {
    update_users_by_pk(pk_columns: { id: $id }, _set: $object) {
      ...UserFragment
    }
  }
`;
