import * as Sentry from '@sentry/vue';
import type { UserModule } from '~/types';

export const install: UserModule = ({ app, router }) => {
  if (import.meta.env.MODE === 'production' || import.meta.env.MODE === 'staging') {
    Sentry.init({
      app,
      dsn: String(import.meta.env.VITE_SENTRY_DSN),
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration({
          maskAllText: false,
          maskAllInputs: false,
          blockAllMedia: false,
        }),
      ],
      ignoreErrors: [
        /Request failed with status code 40[014]/,
      ],
      // Tracing
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost',
        import.meta.env.VITE_BACKEND_URL,
        import.meta.env.VITE_GRAPHQL_URL,
      ],
      // Session Replay
      replaysSessionSampleRate: 0, // This sets the sample rate at 0%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      environment: import.meta.env.MODE,
    });
  }
};
