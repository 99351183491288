import { gql } from '@apollo/client/core';

export const termFragment = gql`
fragment TermFragment on terms {
  id
  client_id
  label
  description
  _created_at
  _updated_at
  _created_by
  _updated_by
}
`;
