import { aiContextFragment } from '#fragments/ai_context';
import { gql } from '@apollo/client/core';

export const AI_CONTEXT_QUERY = gql`
  ${aiContextFragment}
  query GetAiContext {
    ai_context  {
      ...AiContextFragment
    }
  }
`;

export const AI_CONTEXT_UPSERT = gql`
  ${aiContextFragment}
  mutation UpsertAIContext($object: ai_context_insert_input!) {
    insert_ai_context_one(
      object: $object,
      on_conflict: {
        constraint: ai_context_pkey,
        update_columns: [
          company_description
        ]
      },

    ) {
      ...AiContextFragment
    }
  }
`;
