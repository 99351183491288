import { gql } from '@apollo/client/core';

export const clientSettingFragment = gql`
fragment ClientSettingFragment on client_settings {
  id
  client_id
  quote_page_footer
  quote_page_footer_left_image
  quote_page_footer_right_image
  _created_at
  _updated_at
  _created_by
  _updated_by
}
`;
