import type { User, UserRoleEnum } from '@relief/types';
import { USERS_QUERY_LIST } from '@relief/graphql/queries';
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';
import axios from 'axios';
import apolloClient from '~/composables/graphql';
import { fb_auth } from '~/services/firebase';
import { cleanJsonBeforeSend } from '~/utils/api';

export function useGetCurrentUser(enabled?: Ref<boolean>) {
  return useQuery<User>({
    queryKey: ['currentUser'],
    queryFn: async () => {
      const tokenResult = await fb_auth.currentUser?.getIdTokenResult();

      const token = await fb_auth?.currentUser?.getIdToken();
      const response = await axios.get('/v1/users/current', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      let user = response.data as User;

      const claims = tokenResult?.claims;
      if (claims && claims['https://hasura.io/jwt/claims']) {
        const hasuraClaims = claims['https://hasura.io/jwt/claims'] as Record<string, string>;
        user = {
          ...user,
          role: hasuraClaims['x-hasura-default-role'] as UserRoleEnum,
        };
      }
      return {
        ...user,
        language: navigator.language.substring(0, 2),
      } as User;
    },
    enabled,
  });
};

export function useGetUsers(clientId: MaybeRef<number | undefined>, enabled: MaybeRef<boolean> = true) {
  return useQuery<User[]>({
    queryKey: ['users', clientId],
    queryFn: async ({ queryKey }) => {
      const where = queryKey[1] ? { client_id: { _eq: queryKey[1] } } : {};
      const response = await apolloClient.query<{ users: User[] }>({
        query: USERS_QUERY_LIST,
        variables: {
          where,
        },
      });
      return response.data.users;
    },
    enabled: computed(() => !!toValue(enabled)),
  });
}

export function useCreateUser() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (user: User) => {
      const cleanedUser = cleanJsonBeforeSend({ jsonObject: user, keys: ['id', '__typename', 'client', 'language'] });
      const response = await axios.post<User>('/v1/users', cleanedUser);
      return response.data;
    },
    onSuccess: async (_customer: User) => {
      await queryClient.invalidateQueries({
        queryKey: ['users'],
      });
    },
  });
}

export function useUpdateUser() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (user: User) => {
      const cleanedUser = cleanJsonBeforeSend({ jsonObject: user, keys: ['__typename', 'client', 'language', 'email', 'client_id', 'role'] });
      const response = await axios.post<User>('/v1/users/', cleanedUser);
      return response.data;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['users'],
      });
    },
  });
}
