import { gql } from '@apollo/client/core';

export const QUOTE_FEE_INSERT = gql`
  mutation InsertQuoteFee($object: quote_fees_insert_input!) {
    insert_quote_fees_one(object: $object) {
      id
      quote_id
      name
      order
      type
      value
      _created_at
      _created_by
      _updated_at
      _updated_by
    }
  }
`;

export const QUOTE_FEE_UPDATE = gql`
  mutation UpdateQuoteFee($id: Int!, $object: quote_fees_set_input!) {
    update_quote_fees_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
      quote_id
      name
      order
      type
      value
      _created_at
      _created_by
      _updated_at
      _updated_by
    }
  }
`;

export const QUOTE_FEE_DELETE = gql`
  mutation DeleteQuoteFee($id: Int!) {
    delete_quote_fees_by_pk(id: $id) {
      id
      quote_id
      name
      order
      type
      value
      _created_at
      _created_by
      _updated_at
      _updated_by
    }
  }
`;
