import { gql } from '@apollo/client/core';

export const referenceVersionFragment = gql`
fragment ReferenceVersionFragment on reference_versions {
  id
  reference_id
  is_latest
  value
  unit
  change_description
  _created_at
  _updated_at
  _created_by
  _updated_by
}
`;

export const referenceDataFragment = gql`
fragment ReferenceDataFragment on references {
  key
  name
  id
  _created_at
  _updated_at
  _created_by
  _updated_by
}
`;

export const referenceFragment = gql`
${referenceVersionFragment}
${referenceDataFragment}
fragment ReferenceFragment on references {
  ...ReferenceDataFragment
  latest_reference_version(where: { is_latest: { _eq: true } }) {
    ...ReferenceVersionFragment
  }
}
`;

export const formulaReferenceFragment = gql`
${referenceVersionFragment}
fragment FormulaReferenceFragment on formula_references {
  database_version_id
  reference_id
  latest_reference_version(where: { is_latest: { _eq: true } }) {
    ...ReferenceVersionFragment
  }
}
`;
