import { gql } from '@apollo/client/core';

export const tagFragment = gql`
  fragment TagFragment on tags {
    id
    name
    _created_at
    _created_by
    _updated_at
    _updated_by
  }
`;
