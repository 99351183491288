import { databaseObjectFragment, databaseObjectVersionFragment } from '#fragments/databaseObject';
import { gql } from '@apollo/client/core';

export const DATABASE_OBJECT_INSERT = gql`
  ${databaseObjectFragment}
  mutation InsertDatabaseObject($object: database_objects_insert_input!) {
    insert_database_objects_one(object: $object) {
      ...DatabaseObjectFragment
    }
  }
`;

export const BATCH_DATABASE_OBJECT_INSERT = gql`
  ${databaseObjectFragment}
  mutation BulkInsertDatabaseObjects($objects: [database_objects_insert_input!]!) {
    insert_database_objects(objects: $objects) {
      returning {
        ...DatabaseObjectFragment
      }
    }
  }
`;

export const DATABASE_OBJECT_UPDATE = gql`
  ${databaseObjectVersionFragment}
  mutation UpdateDatabaseObject($object: database_object_versions_insert_input!, $id: Int!) {
    update_database_object_versions(
      where: { _and: { database_object_id: { _eq: $id }, is_latest: { _eq: true } } }
      _set: { is_latest: false }
    ) {
      affected_rows
    }
    insert_database_object_versions_one(object: $object) {
      ...DatabaseObjectVersionFragment
    }
  }
`;

export const BATCH_UPDATE_DATABASE_OBJECTS = gql`
  ${databaseObjectVersionFragment}
  mutation BatchUpdateDatabaseObjects($objects: [database_object_versions_insert_input!]!, $ids: [Int!]!) {
    update_database_object_versions(
      where: { database_object_id: { _in: $ids }, is_latest: { _eq: true } }
      _set: { is_latest: false }
    ) {
      affected_rows
    }
    insert_database_object_versions(objects: $objects) {
      returning {
        ...DatabaseObjectVersionFragment
      }
    }
  }
`;

export const DATABASE_OBJECT_QUERY = gql`
  ${databaseObjectFragment}
  query DatabaseObject($id: Int!) {
    database_objects_by_pk(id: $id) {
      ...DatabaseObjectFragment
    }
  }
`;

export const DATABASES_OBJECT_QUERY_LIST = gql`
  ${databaseObjectFragment}
  query DatabaseObjects($databaseId: Int!) {
    database_objects(
      where: { database_id: { _eq: $databaseId }, _deleted_at: { _is_null: true } }
      order_by: { id: asc }
    ) {
      ...DatabaseObjectFragment
    }
  }
`;

export const DATABASE_OBJECT_DELETE = gql`
  mutation DeleteDatabaseObject($id: Int!) {
    update_database_objects_by_pk(pk_columns: { id: $id }, _set: { _deleted_at: "now()" }) {
      id
      _deleted_at
    }
  }
`;

export const BATCH_DELETE_DATABASE_OBJECTS = gql`
  mutation DeleteDatabaseObject($ids: [Int]!) {
    update_database_objects(where: { id: { _in: $ids } }, _set: { _deleted_at: "now()" }) {
      returning {
        id
        _deleted_at
      }
    }
  }
`;

export const DATABASE_OBJECTS_SEARCH_WITH_NAME = gql`
  ${databaseObjectFragment}
  query DatabaseObjectsSearch($nameSearch: String!, $searchOptions: database_objects_bool_exp!) {
    search_database_objects_by_name(args: { name_search: $nameSearch }, where: $searchOptions, limit: 15) {
      ...DatabaseObjectFragment
    }
  }
`;

export const DATABASE_OBJECTS_SEARCH_NO_NAME = gql`
  ${databaseObjectFragment}
  query DatabaseObjectsSearch($searchOptions: database_objects_bool_exp!) {
    database_objects(where: $searchOptions, limit: 15) {
      ...DatabaseObjectFragment
    }
  }
`;
