import { gql } from '@apollo/client/core';
import { referenceVersionFragment } from './references';

export const computedKpiFragment = gql`
  fragment ComputedKPIFragment on computed_kpis {
    id
    label
    uom
    client_id
    _created_at
    _updated_at
    _created_by
    _updated_by
  }
`;

export const databaseKpiVersionFragment = gql`
  fragment DatabaseKpiVersionFragment on database_kpi_versions {
    id
    database_kpi_id
    version
    formula
    is_latest
    _created_at
    _updated_at
    _created_by
    _updated_by
  }
`;

export const databaseKpiDataFragment = gql`
  ${computedKpiFragment}
  fragment DatabaseKPIDataFragment on database_kpis {
    id
    database_id
    computed_kpi_id
    computed_kpi {
      ...ComputedKPIFragment
    }
    _created_at
    _updated_at
    _created_by
    _updated_by
  }
`;

export const databaseKpiFragment = gql`
  ${referenceVersionFragment}
  ${databaseKpiDataFragment}
  ${databaseKpiVersionFragment}
  fragment DatabaseKPIFragment on database_kpis {
    ...DatabaseKPIDataFragment
    database_kpi_versions(where: { is_latest : { _eq: true } }){
      ...DatabaseKpiVersionFragment
      references {
        database_kpi_version_id
        reference_id
        latest_reference_version(where: { is_latest: { _eq: true } }) {
          ...ReferenceVersionFragment
        }
      }
    }
  }
`;
