import { gql } from '@apollo/client/core';

export const quoteVersionFragment = gql`
  fragment QuoteVersionFragment on quote_versions {
    id
    quote_id
    version
    is_latest
    version_json
    date_of_issue
    expiry_date
    _created_by
    _updated_by
    _created_at
    _updated_at
  }
`;
