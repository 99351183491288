import { CommercialGoalFragment } from '#fragments/commercialGoals';
import { gql } from '@apollo/client/core';

export const GOALS_UPSERT = gql`
  ${CommercialGoalFragment}
  mutation UpsertGoals($object: commercial_goals_insert_input!) {
    insert_commercial_goals_one(
      object: $object,
      on_conflict: {
        constraint: commercial_goals_pkey,
        update_columns: [
          time_scale,
          total_turnover,
          total_businesses,
          conversion_rate,
          margin,
          actual_vs_target_margin_deviation
        ]
      }
    ) {
      ...CommercialGoalFragment
    }
  }
`;
