import { taxWithLatestVersionFragment } from '#fragments/taxes';
import { gql } from '@apollo/client/core';
import { databaseObjectFragment } from './databaseObject';
import { moduleRecursiveFragment } from './modules';

export const quoteItemModuleDataFragment = gql`
  fragment QuoteItemModuleDataFragment on quote_item_module {
    id
    data
    value
    margin
    module_id
    quote_section_id
    name
    _created_at
    _created_by
    _updated_at
    _updated_by
  }
`;

export const quoteItemModuleFragment = gql`
  ${quoteItemModuleDataFragment}
  ${moduleRecursiveFragment}
  fragment QuoteItemModuleFragment on quote_item_module {
      ...QuoteItemModuleDataFragment

      module {
        ...ModuleRecursiveFragment
      }
  }
`;

export const quoteItemDatabaseObjectDataFragment = gql`
  fragment QuoteItemDatabaseObjectDataFragment on quote_item_database_object {
    id
    data
    value
    margin
    database_object_id
    quote_section_id
    name
    _created_at
    _created_by
    _updated_at
    _updated_by
  }
`;

export const quoteItemDatabaseObjectFragment = gql`
  ${quoteItemDatabaseObjectDataFragment}
  ${databaseObjectFragment}
  fragment QuoteItemDatabaseObjectFragment on quote_item_database_object {
    ...QuoteItemDatabaseObjectDataFragment

    database_object {
      ...DatabaseObjectFragment
    }
  }
`;

export const quoteItemTaxDataFragment = gql`
  fragment QuoteItemTaxDataFragment on quote_item_taxes {
    id
    quote_section_id
    tax_id
    description
    value
    _created_at
    _created_by
    _updated_at
    _updated_by
  }
`;

export const quoteItemTaxFragment = gql`
  ${quoteItemTaxDataFragment}
  ${taxWithLatestVersionFragment}
  fragment QuoteItemTaxFragment on quote_item_taxes {
    ...QuoteItemTaxDataFragment
    tax {
      ...TaxWithLatestVersionFragment
    }
  }
`;

export const quoteItemSubcontractingFragment = gql`
  fragment QuoteItemSubcontractingFragment on quote_item_subcontractings {
    id
    value
    margin
    name
    attachments
    quote_section_id
    _created_at
    _created_by
    _updated_at
    _updated_by
  }
`;

export const quoteSectionDataFragment = gql`
  fragment QuoteSectionDataFragment on quote_sections {
    id
    quote_id
    name
    description
    order
    _created_at
    _created_by
    _updated_at
    _updated_by
  }
`;

export const quoteSectionFragment = gql`
  ${quoteItemModuleFragment}
  ${quoteItemDatabaseObjectFragment}
  ${quoteSectionDataFragment}
  ${quoteItemSubcontractingFragment}
  ${quoteItemTaxFragment}
  fragment QuoteSectionFragment on quote_sections {
    ...QuoteSectionDataFragment

    quote_item_modules(where: { module: { _deleted_at: { _is_null: true } } }, order_by: {  _created_at: asc }) {
      ...QuoteItemModuleFragment
    }
    quote_item_database_objects(where: { database_object: { _deleted_at: { _is_null: true } } }, order_by: { _created_at: asc }) {
      ...QuoteItemDatabaseObjectFragment
    }
    quote_item_subcontractings(order_by: { _created_at: asc }) {
      ...QuoteItemSubcontractingFragment
    }
    quote_item_taxes(order_by: { _created_at: asc }) {
      ...QuoteItemTaxFragment
    }
  }
`;
