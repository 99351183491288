import { gql } from '@apollo/client/core';
import { databaseDataFragment, databaseVersionFragment } from './database';

export const databaseObjectVersionFragment = gql`
  fragment DatabaseObjectVersionFragment on database_object_versions {
    id
    name
    data
    version
    margin
    database_object_id
    is_latest
    _created_at
    _created_by
    _updated_at
  }
`;

export const databaseObjectDataFragment = gql`
fragment DatabaseObjectDataFragment on database_objects {
  id
  database_id
  _created_at
  _created_by
  _updated_at
  _updated_by
  _deleted_at
}
`;

export const databaseObjectFragment = gql`
  ${databaseObjectDataFragment}
  ${databaseObjectVersionFragment}
  ${databaseVersionFragment}
  ${databaseDataFragment}
  fragment DatabaseObjectFragment on database_objects {
    ...DatabaseObjectDataFragment
    database {
      ...DatabaseDataFragment
      latest_database_version(where: { is_latest: { _eq: true } }) {
        ...DatabaseVersionFragment
      }
    }
    latest_database_object_version(where: { is_latest: { _eq: true } }) {
      ...DatabaseObjectVersionFragment
    }
  }
`;
