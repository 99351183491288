import { gql } from '@apollo/client/core';

export const QUOTE_DISCOUNT_INSERT = gql`
  mutation InsertQuoteDiscount($object: quote_discounts_insert_input!) {
    insert_quote_discounts_one(object: $object) {
      id
      quote_id
      name
      order
      type
      value
      _created_at
      _created_by
      _updated_at
      _updated_by
    }
  }
`;

export const QUOTE_DISCOUNT_UPDATE = gql`
  mutation UpdateQuoteDiscount($id: Int!, $object: quote_discounts_set_input!) {
    update_quote_discounts_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
      quote_id
      name
      order
      type
      value
      _created_at
      _created_by
      _updated_at
      _updated_by
    }
  }
`;

export const QUOTE_DISCOUNT_DELETE = gql`
  mutation DeleteQuoteDiscount($id: Int!) {
    delete_quote_discounts_by_pk(id: $id) {
      id
      quote_id
      name
      order
      type
      value
      _created_at
      _created_by
      _updated_at
      _updated_by
    }
  }
`;
