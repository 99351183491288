import { clientCompanyFragment } from '#fragments/client_companies';
import { gql } from '@apollo/client/core';

export const CLIENT_COMPANY_BY_ID = gql`
  ${clientCompanyFragment}
  query GetClientCompanyById($id: Int!) {
    client_companies_by_pk(id: $id) {
      ...ClientCompanyFragment
    }
  }
`;

export const CLIENT_COMPANY_INSERT = gql`
  ${clientCompanyFragment}
  mutation CreateClientCompany($object: client_companies_insert_input!) {
    insert_client_companies_one(object: $object) {
      ...ClientCompanyFragment
    }
  }
`;

export const CLIENT_COMPANY_UPDATE = gql`
  ${clientCompanyFragment}
  mutation UpdateClientCompany($id: Int!, $object: client_companies_set_input!) {
    update_client_companies_by_pk(pk_columns: { id: $id },_set: $object) {
      ...ClientCompanyFragment
    }
  }
`;

export const CLIENT_COMPANY_SOFT_DELETE = gql`
  mutation SoftDeleteClientCompany($id: Int!) {update_client_companies_by_pk(pk_columns: { id: $id }, _set: { _deleted_at: "now()" }) {
      id
      _deleted_at
    }
  }
`;
