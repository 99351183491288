import { customerCompleteFragment, customerFragment } from '#fragments/customers';
import { gql } from '@apollo/client/core';

export const CUSTOMERS_QUERY_LIST = gql`
  ${customerFragment}
  query CustomersQuery {
    customers {
      ...CustomerFragment
    }
  }
`;

export const CUSTOMERS_QUERY_WITH_NAME = gql`
  ${customerFragment}
  query CustomersQueryWithName($nameSearch: String) {
    search_customers_by_name(args: { name_search: $nameSearch }) {
      ...CustomerFragment
    }
  }
`;

export const CUSTOMERS_QUERY = gql`
  ${customerCompleteFragment}
  query CustomerQuery($id: Int!) {
    customers_by_pk(id: $id) {
      ...CustomerCompleteFragment
    }
  }
`;

export const CUSTOMER_UPDATE = gql`
  ${customerCompleteFragment}
  mutation UpdateCustomer($object: customers_set_input!, $id: Int!) {
    update_customers_by_pk(pk_columns: { id: $id }, _set: $object) {
      ...CustomerCompleteFragment
    }
  }
`;

export const CUSTOMER_INSERT = gql`
  ${customerCompleteFragment}
  mutation InsertCustomer($object: customers_insert_input!) {
    insert_customers_one(object: $object) {
      ...CustomerCompleteFragment
    }
  }
`;

export const CUSTOMER_DELETE = gql`
  mutation DeleteCustomer($id: Int!) {
    delete_customers(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
