import type { AxiosError } from 'axios';
import type { Router } from 'vue-router';
import axios from 'axios';
import { settingsStore } from '~/stores/settings';

export const publicPages = [
  '/auth/login',
  '/auth/reset-pass',
];

export function onUnauthorizedErrorInterceptor(router: Router) {
  return async function (error: AxiosError) {
    if (error) {
      // Ignore cancelled requests
      if (axios.isCancel(error))
        return;
      // Redirect the 401 to the log-in page
      if ((error as AxiosError).response?.status === 401) {
        if (!publicPages.includes(router.currentRoute.value.path)) {
          await router.push({
            path: '/auth/login',
            query: { from: router.currentRoute.value.path },
          });
          settingsStore().setStoresLoadingStating(true);
        }
      }
    }

    return Promise.reject(error);
  };
};
