import { gql } from '@apollo/client/core';

export const CommercialGoalFragment = gql`
  fragment CommercialGoalFragment on commercial_goals {
    id

    client_id
    time_scale
    total_turnover
    total_businesses
    conversion_rate
    margin
    actual_vs_target_margin_deviation

    _created_at
    _created_by
    _updated_at
    _updated_by
  }
`;
