import { contactFragment } from '#fragments/contacts';
import { gql } from '@apollo/client/core';

export const customerFragment = gql`
fragment CustomerFragment on customers {
  id
  client_id
  name
  slug
  address
  zip_code
  city
  country
  phone_number
  company_number
  vat_number
  contact_gender
  contact_name
  attachments

  _created_at
  _created_by
  _updated_at
  _updated_by
}
`;

export const customerTaxFragment = gql`
fragment CustomerTaxFragment on customer_taxes {
  id
  customer_id
  tax_id

  _created_at
  _created_by
  _updated_at
  _updated_by
}
`;

export const customerCompleteFragment = gql`
${customerFragment}
${contactFragment}
${customerTaxFragment}
fragment CustomerCompleteFragment on customers {
  ...CustomerFragment
  contacts {
    ...ContactFragment
  }
  taxes {
    ...CustomerTaxFragment
  }
}
`;
