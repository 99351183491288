import { usersStore } from '~/stores/users';

export enum FeatureName {
  insights = 'insights',
  exportCsv = 'exportCsv',
  dashboard = 'dashboard',
  computedKpis = 'computedKpis',
};
type FeatureFlags = Record<FeatureName, () => boolean>;

const clientFeaturesEnabled: Record<FeatureName, number[]> = {
  [FeatureName.insights]: [],
  [FeatureName.exportCsv]: [],
  [FeatureName.dashboard]: [4],
  [FeatureName.computedKpis]: [4, 2],
};

const isStagingOrDev = import.meta.env.MODE !== 'production';

export const useFeaturesStore = defineStore('features', () => {
  const features = ref<FeatureFlags>({} as FeatureFlags);
  const isEnabled = (feature: FeatureName) => !!features.value[feature]();

  const userStore = usersStore();
  const { clientId } = storeToRefs(userStore);
  function isClientEnabled(featureName: FeatureName) {
    return import.meta.env.MODE === 'production' && clientFeaturesEnabled[featureName].includes(clientId.value || 0);
  };

  function initFlags() {
    features.value = {
      [FeatureName.insights]: () => isStagingOrDev || isClientEnabled(FeatureName.insights),
      [FeatureName.exportCsv]: () => isStagingOrDev || isClientEnabled(FeatureName.exportCsv),
      [FeatureName.dashboard]: () => isStagingOrDev || isClientEnabled(FeatureName.dashboard),
      [FeatureName.computedKpis]: () => isStagingOrDev || isClientEnabled(FeatureName.computedKpis),
    };
  }

  return {
    isEnabled,
    initFlags,
  };
});
