import { gql } from '@apollo/client/core';

export const clientInsightsFragment = gql`
  fragment InsightsFragment on client_insights {
    _created_at
    _updated_at
    frequency
    ignore_reason
    insight_type
    parameters
    status_changed_at
    _created_by
    _updated_by
    id
    status
    value_parameters
    description
    title
    client_id
  }
`;

export const databaseInsightsFragment = gql`
  fragment DatabaseInsightsFragment on database_insights {
    _created_at
    _updated_at
    frequency
    ignore_reason
    insight_type
    parameters
    status_changed_at
    _created_by
    _updated_by
    id
    status
    value_parameters
    description
    database_id
  }
`;

export const moduleInsightsFragment = gql`
  fragment ModuleInsightsFragment on module_insights {
    _created_at
    _updated_at
    frequency
    ignore_reason
    insight_type
    parameters
    status_changed_at
    _created_by
    _updated_by
    id
    status
    value_parameters
    description
    module_id

  }
`;

export const quoteInsightsFragment = gql`
  fragment QuoteInsightsFragment on quote_insights {
    quote_id
    _created_at
    _updated_at
    frequency
    ignore_reason
    insight_type
    parameters
    status_changed_at
    _created_by
    _updated_by
    id
    status
    value_parameters
    description
  }
`;
