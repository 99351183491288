import { gql } from '@apollo/client/core';

export const SITES_QUERY_LIST = gql`
  query SitesQuery {
    sites {
      client_id
      id
      name
    }
  }
`;

export const SITE_QUERY = gql`
  query SiteQuery($id: String!) {
    sites(where: { id: { _eq: $id } }) {
      client_id
      id
      name
    }
  }
`;

export const SITE_UPDATE = gql`
  mutation UpdateSite($object: sites_set_input, $id: Int!) {
    update_sites_by_pk(pk_columns: { id: $id }, _set: $object) {
      client_id
      id
      name
    }
  }
`;

export const SITE_INSERT = gql`
  mutation InsertSite($object: sites_insert_input!) {
    insert_sites_one(object: $object) {
      id
      name
    }
  }
`;
