import { gql } from '@apollo/client/core';

export const clientCompanyFragment = gql`
  fragment ClientCompanyFragment on client_companies {
    id
    client_id
    is_default
    name
    attachments
    address
    zip_code
    city
    country
    phone_number
    company_number
    vat_number
    quote_page_footer
    quote_page_footer_left_image
    quote_page_footer_right_image
    _deleted_at
    _created_at
    _updated_at
    _created_by
    _updated_by
  }
`;
