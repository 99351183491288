import { databaseInsightsFragment } from '#fragments/insight';
import { databaseKpiFragment } from '#fragments/kpis';
import { formulaReferenceFragment } from '#fragments/references';
import { gql } from '@apollo/client/core';

export const databaseVersionDataFragment = gql`
  fragment DatabaseVersionDataFragment on database_versions {
    attributes
    attachments
    id
    database_id
    name
    description
    formula
    version
    is_latest
    default_margin
    _created_at
    _created_by
    _updated_at
    _updated_by
  }
`;

export const databaseVersionFragment = gql`
  ${databaseVersionDataFragment}
  ${formulaReferenceFragment}
  fragment DatabaseVersionFragment on database_versions {
    ...DatabaseVersionDataFragment
    references {
      ...FormulaReferenceFragment
    }
  }
`;

export const databaseDataFragment = gql`
  ${databaseInsightsFragment}
  ${databaseKpiFragment}
  fragment DatabaseDataFragment on databases {
    id
    client_id
    _created_at
    _created_by
    _updated_at
    _updated_by
    insights(order_by: { _created_at: desc },where: { status: { _is_null: true } }){
      ...DatabaseInsightsFragment
    }
    database_kpis {
      ...DatabaseKPIFragment
    }
  }
`;
