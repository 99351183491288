import { defineStore } from 'pinia';

export const settingsStore = defineStore({
  id: 'settings',

  state: () => ({
    stores_loaded: false,
    stores_loading_msg: 'Loading stores...',

    mobileMenuOpen: false,
    desktopMenuOpen: true,
  }),

  getters: {
    getMobileMenuOpen(): any {
      return this.mobileMenuOpen;
    },
    getPcMenuOpen(): any {
      return this.mobileMenuOpen;
    },
  },

  actions: {
    setStoresLoadingStating(state: boolean = true) {
      this.stores_loaded = state;
    },
    setStoresLoadingMsg(msg: string) {
      this.stores_loading_msg = msg;
    },

    setMobileMenuOpen(value: boolean) {
      this.mobileMenuOpen = value;
    },
    setDesktopMenuOpen(value: boolean) {
      this.desktopMenuOpen = value;
    },
  },
});
